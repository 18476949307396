<template>
  <div class="breadcrumbs-main" :key="currentPath">
    <div class="breadcrumbs-section">
      <div class="container-fluid bc-container">
        <div class="row">
          <div class="col-sm-12">
            <ol class="breadcrumbWrpr" v-if="breadCrumbs && breadCrumbs[route.name] && breadCrumbs[route.name]?.length">
              <li class="breadcrumbs-links" v-for="(crumb, index) in breadCrumbs[route.name]" :key="index">
                <template v-if="index">
                  {{ "/" }}
                </template>
                <router-link class="breadcrumbs-links" :to="crumb.path || currentPath">{{
    $t(`routes.${crumb.text}`, crumb.text) }} </router-link>
              </li>
            </ol>
            <!-- <ol v-else-if="productInfoBreadCrumbs && productInfoBreadCrumbs.length">
              <li class="breadcrumbs-links" v-for="(crumb, index) in productInfoBreadCrumbs" :key="index">
                <template v-if="index">
                  {{ "/" }}
                </template>
                <router-link class="breadcrumbs-links" :to="crumb.path || currentPath">{{ crumb.text }} </router-link>
              </li>
            </ol> -->

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useFilterStore } from '~/store/FilterStore'
const route = useRoute();
const router = useRouter();
const filterStore = useFilterStore();
const currentPath = computed(() => router.currentRoute.value.path);
const breadCrumbs = computed(() => filterStore.breadCrumbs.find(x => x[route.name]))
// const productInfoBreadCrumbs = computed(() => filterStore.productInfoBreadCrumbs)
</script>
<style>
.bc-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
</style>