<script setup>
import { useCategoryStore } from "~/store/CategoryStore";
import { useFilterStore } from "~/store/FilterStore";
import { useUserStore } from "~/store/UserStore";
import { useUtilityStore } from "~~/store/UtililityStore";
import { EventBus } from "~/utils/eventBus.js";

const props = defineProps([
    "categorySidebarShow"
]);
const router = useRouter();
const filterStore = useFilterStore()
const utilityStore = useUtilityStore();
const categoryStore = useCategoryStore();
const lables = computed(() => categoryStore.categoryLables)
const filters = computed(() => categoryStore.fullCategoryFilters)
const userStore = useUserStore();
const categoriesPreference = computed(() => userStore.categoriesPreference);
const toggleCategory = ref({ key: false });
const selectedLanguage = computed(() => utilityStore.selectedLanguage)
const categoryfilterState = computed(() => filterStore.categoryfilterState)
const localePath = useLocalePath();

const activeCategoryFiltersOnly = computed(() => {

    return filters.value && filters.value?.filter ? filters.value?.filter(
        (x) =>
            !categoriesPreference.value[x.key] ||
            !categoriesPreference.value[x.key].status === false
    ) : [];
})
const childAggregated = computed(() => activeCategoryFiltersOnly.value?.map(x => x['child_agg']['buckets']))


const getLable = computed(() => {
    return (key) => {
        if (
            lables.value[key] &&
            Object.prototype.hasOwnProperty.call(
                lables.value[key],
                selectedLanguage.value
            )
        ) {
            return lables.value[key][selectedLanguage.value];
        } else if (
            lables.value[key] &&
            Object.prototype.hasOwnProperty.call(lables.value[key], "fr_BE")
        ) {
            return lables.value[key]["fr_BE"];
        } else return key;
    };
})
const expandHandler = (filterKey, isParent = false, parentname = "") => {
    EventBus.emit("setExpandCategory", { filterKey, isParent, parentname });
}
const performFiltering = () => {
    const filterv = Object.values(categoryfilterState.value.grandchild)
        .filter((x) => x.value === true && x.key)
        .map((k) => k.key);

    filterStore.setSelectedCategoryFilters(filterv);



}
const handleFilterChildMulti = ({ parent, childBuckets }) => {
    const checked = true;
    let _parent = {},
        _child = {},
        _grandchild = {};
    for (let i = 0; i < childBuckets.length; i++) {
        if (
            childBuckets[i].sub_child_agg &&
            childBuckets[i].sub_child_agg.buckets.length
        ) {
            _grandchild = {
                ..._grandchild,
                ...Object.assign(
                    {},
                    ...childBuckets[i].sub_child_agg.buckets.map((x) => {
                        return {
                            [x.key]: { value: checked, show: checked, key: x.key },
                        };
                    })
                ),
            };
            _child = {
                ..._child,
                [childBuckets[i].key]: { value: checked, show: checked },
            };
        } else {
            _grandchild = {
                ..._grandchild,
                [childBuckets[i].key]: {
                    value: checked,
                    show: checked,
                    key: childBuckets[i].key,
                },
            };
        }
    }
    filterStore.setCategoryfilterState({
        parent: {
            [parent]: { value: checked, show: checked },
        },
        child: { ..._child, all: true },
        grandchild: {
            ..._grandchild,
        },
    });

    setTimeout(() => {
        router.push({ path: localePath('/products/search') })
    }, 500);
    setTimeout(() => {
        expandHandler(parent, true);
        performFiltering();
    }, 800);
}
const ParentCategoryHandler = (filter) => {
    const childBuckets = activeCategoryFiltersOnly.value.find(x => x.key === filter.key)['child_agg']['buckets'] //.map(x => x.buckets);//.map(x => x.key)
    handleFilterChildMulti({ childBuckets, parent: filter.key })
}


const ChildCategoryHandler = (childCategory) => {
    // console.log("filter child category", childCategory);
    // console.log("filter Subchild category", childCategory);
    // console.log("category", activeCategoryFiltersOnly.value);
    let topParent;
    for (let i = 0; i < activeCategoryFiltersOnly.value.length; i++) {
        const buckets = activeCategoryFiltersOnly.value[i].child_agg.buckets
        topParent = buckets.find(x => x.key === childCategory.key)
        if (topParent) {
            topParent = activeCategoryFiltersOnly.value[i].key
            break;
        }
    }
    // console.log("filter child category", childCategory);
    // console.log("topParent", topParent);

    const checked = true;
    let _child = {}, _grandchild = {};

    if (childCategory['sub_child_agg']['buckets'].length) {
        _grandchild = {
            ...Object.assign(
                {},
                ...childCategory['sub_child_agg']['buckets'].map((x) => {
                    return {
                        [x.key]: { value: checked, show: checked, key: x.key },
                    };
                })
            ),
        };
        _child = {
            [childCategory.key]: { value: checked, show: checked },
        };
    }
    else {
        _grandchild = {
            [childCategory.key]: {
                value: checked,
                show: checked,
                key: childCategory.key,
            },
        };
    }
    filterStore.setCategoryfilterState({
        parent: {
            [topParent]: { value: checked, show: checked },
        },
        child: { ..._child, all: true },
        grandchild: {
            ..._grandchild,
        },
    });


    setTimeout(() => {
        router.push({ path: localePath('/products/search') })
    }, 500);
    setTimeout(() => {
        expandHandler(childCategory.key, false, topParent);
        performFiltering();
    }, 800);
}
// useAsyncData('fetch-all-categories', async () => {
//    return await categoryStore.fetchAllCategories()
// });
onBeforeMount(() => {
    categoryStore.fetchAllCategories();
});

const image = useImage()
const getImgPath = (key) => `/category-images/${key}.svg`
</script>
<template>

    <div class="container printPdf">
        <div class="mega-menu">
            <div class="navbar">
                <div class="dropdown">
                    <button class="dropbtn">
                        <b><span><i class="fa fa-bars"></i></span> {{ $t('common.categories', 'Categories') }} <span><i
                                    class="fa fa-caret-down"></i></span></b>
                    </button>

                    <div class="dropdown-content">
                        <div class="header">
                        </div>
                        <div class="row">
                            <div class="column mega-side-bar w-25">
                                <ul class="mega-level-menu category">
                                    <li v-for="(filter, index) in activeCategoryFiltersOnly" :key="index"
                                        class="category-list" @mouseover="toggleCategory = { key: index }">
                                        <a href="#" @click="ParentCategoryHandler(filter)" class="main-cate">
                                            <NuxtImg :src="getImgPath(filter['key'])" class="ctg-mega-img" />
                                            {{ getLable(filter["key"]) }}
                                            +{{ filter["doc_count"] }}
                                            <i class="fa fa-angle-right arrow" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="column mega-side-bar w-75">
                                <div class="mega-side-bar-right">
                                    <ul v-for="(childCategories, ic) in childAggregated" :key="childCategories"
                                        class="category-child "
                                        :class="{ show: toggleCategory['key'] === ic, none: toggleCategory['key'] !== ic }">
                                        <li v-for="(childCategory, icc) in childCategories" class="category-list-child">
                                            <a href="#" @click="ChildCategoryHandler(childCategory)"> {{
                                                getLable(childCategory["key"]) }}
                                                +{{ childCategory["doc_count"] }}
                                                <i class="fa fa-angle-right nested-arrow" aria-hidden="true"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scopped>
.ctg-mega-img {
    margin-left: 10px;
    height: 15px;

}

.main-cate:hover i {
    color: var(--theme-deafult);

}

.arrow {
    position: absolute;
    right: 20px;
}

.nested-arrow {
    margin: 0 0 0 10px;
    color: var(--theme-deafult);
}
</style>
